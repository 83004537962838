






















































.client-page {
    position: relative;
    &::-webkit-scrollbar {
        width: 0;
    }
}
.v-main--wrap {
    display: flex;
    flex: 1 0 auto;
    max-width: 100%;
    padding: 56px 0;
}
.v-application .main--white{
    background-color: #fff !important;
}
.btn-area {
    pointer-events: none;

    position: fixed;
    top: 0;
    height: 100vh;

    > .v-btn {
        pointer-events: auto;
    }
}
.btn-floating {
    box-shadow: var(--shadow);
    transform: translateY(-50%);
    margin-bottom: calc(48px + 16px);
}
